import {
  APP_SET_SELECTED_MODEL_RESULT_ACTION,
  APP_SET_SELECTED_TOPIC_ACTION,
  APP_SET_BREADCRUMBS_DATA_ACTION,
  APP_SET_SELECTED_MODEL_RESULT_DETAIL_ACTION,
  APP_SET_SELECTED_MODEL_ID_ACTION,
  APP_SET_TOPIC_TIME_EVOLUTION_DATA,
  APP_CLEAR_SELECTED_TOPIC_ACTION,
  APP_SET_SELECTED_DATE_RANGE,
  APP_CLEAR_SELECTED_DATE_RANGE,
  APP_UPDATE_SELECTED_TOPIC_NAME,
  APP_GET_TOPIC_GROUPS_SUCCESS_ACTION,
  APP_GET_TOPIC_GROUP_MEMBERSHIPS_SUCCESS_ACTION,
  APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_SUCCESS_ACTION,
  APP_UNSET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION,
  APP_SET_SELECTED_SENTIMENT_ACTION,
  APP_SET_SELECTED_CATEGORY_ACTION,
  APP_CLEAR_SELECTED_SENTIMENT_ACTION,
  APP_CLEAR_SELECTED_CATEGORY_ACTION,
  APP_SET_SELECTED_TOPIC_GROUP_ACTION,
  APP_SET_SELECTED_ENTITY_ACTION,
  APP_CLEAR_SELECTED_ENTITY_ACTION,
  APP_SET_SELECTED_PLAYER_ACTION,
  APP_CLEAR_SELECTED_PLAYER_ACTION,
  APP_SET_METADATA_SUMMARY_ACTION,
  APP_CLEAR_METADATA_SUMMARY_ACTION,
  APP_SET_METADATA_FILTER_SELECTION_ACTION,
  APP_CLEAR_METADATA_FILTER_SELECTION_ACTION,
  APP_ACTIVATE_ALL_METADATA_FILTERS_ACTION,
  APP_SET_MODEL_ANALYSIS_UNIT_COUNT,
  APP_CLEAR_MODEL_ANALYSIS_UNIT_COUNT,
  APP_SET_MODEL_SENTIMENT_DISTRIBUTION,
  APP_CLEAR_MODEL_SENTIMENT_DISTRIBUTION,
  APP_SET_MODEL_TOPIC_SEGMENTS_COUNT,
  APP_CLEAR_MODEL_TOPIC_SEGMENTS_COUNT,
  APP_SET_TEXT_QUERY_ACTION,
  APP_SET_MODEL_SETTINGS_ACTION,
  APP_SET_MODEL_BATCHES_ACTION,
  APP_CLEAR_MODEL_RESULTS_STATE_ACTION,
  APP_SET_KB_DATA_SOURCES_ACTION,
  APP_SET_SUNBURST_DATA,
} from "../constants";
import { ModelSettings, TopicGroup, TopicGroupMembership } from "../dal";
import { DiffCheckerHealthyResponse } from "../dal/kb";
import { metadataSummaryElement } from "../dal/metadata";
import { SentimentDistributionType } from "../dal/sentiment";
import { AnalysisBatch } from "./project";
import { TopicTimeEvolutionData } from "./trends";

export type CategoricalOption = {
  label: string;
  value: string;
  enabled?: boolean;
  count?: number;
};

export type NumericalSelectedFilterState = {
  label: string;
  value: string;
  enabled?: boolean;
};
export type MultiCategoricalSelectedFilterState = CategoricalOption[];
export type SingleCategoricalSelectedFilterState = CategoricalOption;

export type SelectedFilterState =
  | NumericalSelectedFilterState
  | MultiCategoricalSelectedFilterState
  | SingleCategoricalSelectedFilterState;

export type SelectedFilters = {
  [filterKey: string]: SelectedFilterState;
};

export enum ExistingBaseFilters {
  category = "category",
  sentiment = "sentiment",
  topic_uuid = "topic_uuid",
  entity_id = "entity_id",
}

export enum ExistingMDFilter {
  metadata = "metadata",
}

export type ExistingFilters = [
  ExistingBaseFilters.category,
  ExistingBaseFilters.entity_id,
  ExistingBaseFilters.sentiment,
  ExistingBaseFilters.entity_id,
  ExistingMDFilter.metadata
];

export type BaseFilterSettingsType = {
  [key in ExistingBaseFilters]: string | undefined;
};

export type MDFilterSettingsType = {
  [key in ExistingMDFilter]: undefined | { [key: string]: string | string[] };
};

export type FilterSettingsType = BaseFilterSettingsType & MDFilterSettingsType;

export type SunburstData = any;

export type ModelResultMetric = {
  name: string;
  value: number | string;
};

export type ModelResultMember = {
  id: string;
  data: {
    text: string;
    member_id: number;
    topic_sim: number;
  };
};

export type ModelResultHeader = {
  version: string;
  kind: string;
  metadata_mapping: Map<string, string>;
  metadata_ranges: Map<string, any>;
};

export type ModelResultData = {
  sunburst: SunburstData;
  mdData: { [key: string]: any };
};

export type ModelResult = {
  header: ModelResultHeader;
  data: ModelResultData;
};

export type DateRange = {
  min: string;
  max: string;
  active: boolean;
};

export type ModelResultDetail = {
  id: string;
  result_id: string;
  data: {
    name: string;
    size: number;
    keywords: string;
    label: string;
    level: number;
    id: string;
    metrics: ModelResultMetric[];
    metadata_views: Map<string, Map<string, any>>;
  };
  header: any;
  name: {
    value: string;
  };
  members: ModelResultMember[];
  uri_s3: string;
  topicGroupMemberships?: TopicGroupMembership[];
};

export type mdFilter = {
  label: string;
  value: string;
};

export type SentimentLimits = {
  lower_limit: number;
  upper_limit: number;
};

export type AppModelResultsState = {
  countAnalysisUnits?: number;
  countTopicSegments?: number;
  sentimentDistribution?: SentimentDistributionType;
  selectedModelId?: number;
  selectedTopic?: string;
  selectedTopicName?: string;
  selectedLevel?: number;
  selectedResult?: ModelResult;
  selectedResultDetail?: ModelResultDetail;
  selectedTopicTimeEvolution?: TopicTimeEvolutionData;
  selectedDateRange?: DateRange;
  selectedSentiment?: string;
  selectedCategory?: string;
  selectedEntity?: string;
  selectedTopicGroupId?: string;
  selectedPlayer?: string;
  breadcrumbs?: string[];
  topicGroups?: TopicGroup[];
  topicGroupsMembershipsByGroups?: { [index: string]: TopicGroupMembership[] };
  metadataSummary?: metadataSummaryElement[];
  selectedMetadataFilter?: SelectedFilters; //{ [key: string]: mdFilter | mdFilter[] };
  preparedMetadataFilter?: SelectedFilters; //{ [key: string]: mdFilter | mdFilter[] };
  query?: string;
  is_searchable?: boolean;
  sentiment_limits?: SentimentLimits;
  selectedModelBatches?: AnalysisBatch[];
  kbDataSources?: DiffCheckerHealthyResponse;
  sunburst?: any;
};

export const initialState = {};

type ModelResultAction = {
  type: string;
  payload: {
    model_id: number;
    topic: string;
    topic_name: string;
    level: number;
    name: string;
    breadcrumbs: string[];
    result: ModelResult;
    result_detail: ModelResultDetail;
    topic_time_evolution: TopicTimeEvolutionData;
    date_range: DateRange;
    topic_groups: TopicGroup[];
    topic_group_memberships: TopicGroupMembership[];
    group_id: string;
    selected_value: string;
    metadata_summary: { summary: metadataSummaryElement[] };
    metadata_filters: SelectedFilters; //{ [key: string]: mdFilter | mdFilter[] };
    n_analysis_units: number;
    n_topic_segments: number;
    sentiment_distribution: SentimentDistributionType;
    query: string;
    is_searchable: boolean;
    settings: ModelSettings;
    batches: AnalysisBatch[];
    diff: DiffCheckerHealthyResponse;
    sunburst: any;
  };
};

const modelResultsReducer = (
  state: AppModelResultsState = initialState,
  action: ModelResultAction
) => {
  const { payload, type } = action;

  switch (type) {
    case APP_SET_KB_DATA_SOURCES_ACTION:
      return {
        ...state,
        kbDataSources: action.payload.diff,
      };
    case APP_CLEAR_MODEL_RESULTS_STATE_ACTION:
      return {};
    case APP_SET_MODEL_BATCHES_ACTION:
      return {
        ...state,
        selectedModelBatches: action.payload.batches,
      };
    case APP_SET_MODEL_SETTINGS_ACTION:
      return {
        ...state,
        sentiment_limits: payload.settings.sentiment,
        is_searchable: payload.is_searchable,
      };
    case APP_SET_TEXT_QUERY_ACTION:
      return {
        ...state,
        query: payload.query,
      };

    case APP_SET_MODEL_SENTIMENT_DISTRIBUTION:
      return {
        ...state,
        sentimentDistribution: payload.sentiment_distribution,
      };
    case APP_CLEAR_MODEL_SENTIMENT_DISTRIBUTION:
      return {
        ...state,
        sentimentDistribution: undefined,
      };
    case APP_SET_MODEL_ANALYSIS_UNIT_COUNT:
      return {
        ...state,
        countAnalysisUnits: payload.n_analysis_units,
      };
    case APP_CLEAR_MODEL_ANALYSIS_UNIT_COUNT:
      return {
        ...state,
        countAnalysisUnits: undefined,
      };
    case APP_SET_MODEL_TOPIC_SEGMENTS_COUNT:
      return {
        ...state,
        countTopicSegments: payload.n_topic_segments,
      };
    case APP_CLEAR_MODEL_TOPIC_SEGMENTS_COUNT:
      return {
        ...state,
        countTopicSegments: undefined,
      };
    case APP_SET_METADATA_FILTER_SELECTION_ACTION:
      return {
        ...state,
        preparedMetadataFilter: payload.metadata_filters,
      };

    case APP_ACTIVATE_ALL_METADATA_FILTERS_ACTION:
      const currentFilters = state.preparedMetadataFilter ?? {};
      var filters: SelectedFilters = {};
      for (const k of Object.keys(currentFilters)) {
        var elemFilters = currentFilters[k];
        if (elemFilters instanceof Array) {
          const vals = elemFilters.map((elem) => {
            return {
              ...elem,
              ...{ enabled: true },
            };
          });
          filters[k] = vals;
        } else {
          filters[k] = { ...elemFilters, ...{ enabled: true } };
        }
      }
      return {
        ...state,
        selectedMetadataFilter: filters,
      };
    case APP_CLEAR_METADATA_FILTER_SELECTION_ACTION:
      return {
        ...state,
        selectedMetadataFilter: undefined,
        preparedMetadataFilter: undefined,
      };
    case APP_SET_METADATA_SUMMARY_ACTION:
      return {
        ...state,
        metadataSummary: payload.metadata_summary["summary"],
      };
    case APP_CLEAR_METADATA_SUMMARY_ACTION:
      return { ...state, metadataSummary: undefined };
    case APP_SET_SELECTED_MODEL_RESULT_ACTION:
      return { ...state, selectedResult: payload.result };

    case APP_SET_SELECTED_MODEL_RESULT_DETAIL_ACTION:
      return { ...state, selectedResultDetail: payload.result_detail };

    case APP_SET_SELECTED_TOPIC_ACTION:
      return {
        ...state,
        selectedTopic: payload.topic,
        selectedLevel: payload.level,
        selectedTopicName: payload.topic_name,
      };

    case APP_SET_SELECTED_SENTIMENT_ACTION:
      return { ...state, selectedSentiment: payload.selected_value };

    case APP_SET_SELECTED_PLAYER_ACTION:
      return { ...state, selectedPlayer: payload.selected_value };

    case APP_SET_SELECTED_ENTITY_ACTION:
      return { ...state, selectedEntity: payload.selected_value };

    case APP_SET_SELECTED_CATEGORY_ACTION:
      return { ...state, selectedCategory: payload.selected_value };

    case APP_SET_SELECTED_TOPIC_GROUP_ACTION:
      return { ...state, selectedTopicGroupId: payload.selected_value };

    case APP_CLEAR_SELECTED_SENTIMENT_ACTION:
      return { ...state, selectedSentiment: undefined };

    case APP_CLEAR_SELECTED_PLAYER_ACTION:
      return { ...state, selectedPlayer: undefined };

    case APP_CLEAR_SELECTED_ENTITY_ACTION:
      return { ...state, selectedEntity: undefined };

    case APP_CLEAR_SELECTED_CATEGORY_ACTION:
      return { ...state, selectedCategory: undefined };

    case APP_CLEAR_SELECTED_TOPIC_ACTION:
      return {
        ...state,
        ...{ selectedTopic: undefined, selectedTopicName: undefined },
      };

    case APP_SET_SELECTED_MODEL_ID_ACTION:
      return { ...state, selectedModelId: payload.model_id };

    case APP_SET_BREADCRUMBS_DATA_ACTION:
      return { ...state, breadcrumbs: payload.breadcrumbs };

    case APP_SET_TOPIC_TIME_EVOLUTION_DATA:
      return {
        ...state,
        selectedTopicTimeEvolution: payload.topic_time_evolution,
      };

    case APP_SET_SELECTED_DATE_RANGE:
      return { ...state, selectedDateRange: payload.date_range };

    case APP_CLEAR_SELECTED_DATE_RANGE:
      return { ...state, selectedDateRange: undefined };

    case APP_UPDATE_SELECTED_TOPIC_NAME:
      var current = state.selectedResultDetail;
      if (current) {
        current.name = { value: payload.name };
      }
      return { ...state, ...{ selectedResultDetail: current } };

    case APP_GET_TOPIC_GROUPS_SUCCESS_ACTION:
      return {
        ...state,
        topicGroups: payload.topic_groups,
      };

    case APP_GET_TOPIC_GROUP_MEMBERSHIPS_SUCCESS_ACTION:
      var currentRD = state.selectedResultDetail;
      if (currentRD) {
        currentRD.topicGroupMemberships = payload.topic_group_memberships;
      }
      return { ...state, ...{ selectedResultDetail: currentRD } };

    case APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_SUCCESS_ACTION:
      var currentGroupsByGroup = state.topicGroupsMembershipsByGroups;
      if (!currentGroupsByGroup) {
        currentGroupsByGroup = {};
      }
      currentGroupsByGroup[payload.group_id] = payload.topic_group_memberships;
      return {
        ...state,
        ...{ topicGroupsMembershipsByGroups: currentGroupsByGroup },
      };

    case APP_UNSET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION:
      return { ...state, ...{ topicGroupsMembershipsByGroups: undefined } };

    case APP_SET_SUNBURST_DATA:
      return {
        ...state,
        sunburst: action.payload.sunburst,
      };

    default:
      return state;
  }
};

export { modelResultsReducer };
